import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestApiService } from '../shared/services/rest-api.service';
@Injectable()
export class CustomerStoriesService {
  constructor(private restApiService: RestApiService) {}

  getCustomerStoriesDetails(id) {
    return this.restApiService.get('get-user-details', `/api/v1/customer-feedbacks/${id}`);
  }
  public postCustomerStories(d, data): Observable<any> {
    return this.restApiService.post(d, '/api/v1/customer-feedbacks', data, 'page-center');
  }
  updateCustomerStoriesDetails(id: any, payload: any) {
    return this.restApiService.put('update-user', `/api/v1/customer-feedbacks/${id}`, payload);
  }
}
