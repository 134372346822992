import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormControlName, FormGroup } from '@angular/forms';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';
import * as utils from '../../../utility-functions/utils';
interface ArrPayload {
  name: any;
  value: any;
  image?: string;
}

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnChanges, OnInit, AfterViewInit {
  @ViewChild('Input') input: ElementRef;
  @Input() inputValue;
  @Input() inputId;
  @Output() onValueChange = new EventEmitter<any>();
  @Output() completeMethod = new EventEmitter<any>();
  @Output() onClick = new EventEmitter<any>();
  @Output() selectedValue: ArrPayload;
  @Output() selectedValues: [ArrPayload];
  @Output() unselect = new EventEmitter<any>();
  @Input() label: any;
  @Input() overflowLabel = false;
  @Input() parent: FormGroup;
  @Input() formCtrlName: FormControlName;
  @Input() inputType = 'text';
  @Input() bracketLabel: string | number;
  @Input() isRequired = false;
  @Input() maxLength: string;
  @Input() dataCy = '';
  @Input() arrayPayloadAuto: any;
  @Input() arrayPayload: [ArrPayload];
  @Input() icon: string;
  @Input() iconLocation = 'right';
  @Input() placeholder: any = '';
  @Input() autoCompleteIcon;
  @Input() rows = 3;
  @Input() groupAutoInsertType = '';
  @Input() separator;
  @Input() fieldLabel;
  @Input() optionLabel = 'name';
  @Input() optionValue = 'value';
  @Input() optionDisabled = 'inactive';
  @Input() datakey = 'value';
  @Input() field: string;
  @Input() multiple = false;
  @Input() value;
  @Input() selectionLimit;
  @Input() errorMessage;
  @Input() disabled = false;
  @Input() maxlength;
  @Input() inputGroupIconClass: 'pi pi-' | string = 'pi pi-trash';
  @Input() inputGroupIcon: 'start' | 'end' = 'end';
  @Input() minlength: string;
  @Input() labelWithCurrencySymbol = false;
  @Input() currency = 'USD';
  @Input() locale = 'en-US';
  @Input() min;
  manualInsert = true;
  autoComplete = false;

  constructor(public commonBindingDataService: CommonBindingDataService) {}

  ngOnChanges(): void {}

  ngOnInit(): void {
    this.parent.controls[`${this.formCtrlName}`].valueChanges.subscribe((ele) => {});
  }

  ngAfterViewInit() {
    this.input?.nativeElement?.setAttribute('aria-describedby', `${this.formCtrlName}-help`);
    if (this.dataCy) {
      this.input?.nativeElement?.setAttribute('data-cy', `${this.formCtrlName || this.dataCy}`);
    }
  }

  onChange(changes) {
    this.onValueChange.emit(changes);
  }

  onUnselect(changes) {
    this.unselect.emit(changes);
  }

  onAutoComplete(changes) {
    this.completeMethod.emit(changes);
  }

  validateInput(ctrlName) {
    return this.parent.controls[ctrlName].errors && (this.parent.controls[ctrlName].dirty || this.parent.controls[ctrlName].touched);
  }

  validateError(ctrlName) {
    if (this.parent.controls[ctrlName].errors && this.parent.controls[ctrlName].dirty && this.parent.controls[ctrlName].touched) {
      return 'ng-invalid ng-dirty';
    } else {
      return '';
    }
  }

  validateDropdownInput(ctrlName) {
    return this.parent.controls[ctrlName].errors && this.parent.controls[ctrlName].touched;
  }

  validateDropdownError(ctrlName) {
    if (this.parent.controls[ctrlName].errors && this.parent.controls[ctrlName].touched) {
      return 'ng-invalid';
    } else {
      return '';
    }
  }

  filterAutocomplete(e) {}
  click(e) {
    this.onClick.emit(e);
  }

  noSpaceAtStart(event: any): boolean {
    if (event?.target.value?.length !== 0) {
      return true;
    } else {
      return utils.noSpaceAtStart(event);
    }
  }
}
