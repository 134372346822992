<div class="p-fluid grid" [formGroup]="parent">
  <div class="col-12">
    <div [class]="overflowLabel ? 'label-text overflowLabel-class' : 'label-text'" *ngIf="label || fieldLabel">
      <label translate for="{{ formCtrlName }}" class="block widthAuto">
        {{
          labelWithCurrencySymbol
            ? (label | translate) +
              ' ' +
              commonBindingDataService.createCurrencySymbol(commonBindingDataService.getCurrentCurrencyAndLocaleData().currencyCode)
            : (label | translate)
        }}
        <span *ngIf="bracketLabel" translate>
          {{ bracketLabel }}
        </span>
        <span *ngIf="isRequired" class="required-star">*</span>
        <span *ngIf="fieldLabel" translate class="floatLabel">
          <span class="pi pi-exclamation-circle"></span> {{ fieldLabel }}
        </span></label
      >
    </div>

    <!-- Number with decimal -->
    <span *ngIf="inputType === 'decimal'">
      <p-inputNumber
        #Input
        id="{{ formCtrlName }}"
        [formControlName]="formCtrlName"
        inputId="locale-us"
        mode="decimal"
        inputId="withoutgrouping"
        [useGrouping]="false"
        [minFractionDigits]="2"
        [maxFractionDigits]="2"
      >
      </p-inputNumber>
      <small *ngIf="validateInput(formCtrlName)" id="{{ formCtrlName }}-help" class="small-error p-error block"
        ><span translate>{{ label | translate }} </span> {{ 'isRequired' | translate }}</small
      >
    </span>

    <span *ngIf="inputType === 'positive'">
      <p-inputNumber
        #Input
        id="{{ formCtrlName }}"
        [formControlName]="formCtrlName"
        inputId="minmax"
        mode="decimal"
        inputId="withoutgrouping"
        [useGrouping]="false"
        [min]="0"
        [max]="10000"
      >
      </p-inputNumber>
      <small *ngIf="validateInput(formCtrlName)" id="{{ formCtrlName }}-help" class="small-error p-error block"
        ><span translate>{{ label | translate }} </span> {{ 'isRequired' | translate }}</small
      >
    </span>

    <span *ngIf="inputType === 'contactNumber'">
      <p-inputNumber
        #Input
        id="{{ formCtrlName }}"
        [maxlength]="maxlength"
        [formControlName]="formCtrlName"
        inputId="locale-us"
        mode="decimal"
        inputId="withoutgrouping"
        [min]="min"
        (onInput)="onChange($event)"
        [useGrouping]="false"
        [placeholder]="placeholder | translate"
        [disabled]="disabled"
        [ngClass]="validateError(formCtrlName)"
      >
      </p-inputNumber>
      <small
        *ngIf="
          parent.get(formCtrlName).hasError('required') && (parent.controls[formCtrlName].dirty || parent.controls[formCtrlName].touched)
        "
        id="{{ formCtrlName }}-help"
        class="small-error p-error block"
        ><span translate>{{ errorMessage || label | translate }} </span> {{ 'isRequired' | translate }}</small
      >
      <small
        *ngIf="
          !parent.get(formCtrlName).hasError('required') &&
          (parent.controls[formCtrlName].dirty || parent.controls[formCtrlName].touched) &&
          parent.controls[formCtrlName].invalid
        "
        id="{{ formCtrlName }}-help"
        class="small-error p-error block"
        ><span translate>min_length_10dig </span></small
      >
    </span>

    <!-- Input type number -->
    <span *ngIf="inputType === 'number'">
      <p-inputNumber
        #Input
        [placeholder]="placeholder | translate"
        id="{{ formCtrlName }}"
        [formControlName]="formCtrlName"
        inputId="locale-us"
        [min]="min"
        mode="decimal"
        locale="en-US"
        [minFractionDigits]="2"
        (onInput)="onChange($event)"
        [ngClass]="validateError(formCtrlName)"
        [currency]="currency"
        [locale]="locale"
        decimalSeparator=""
        [disabled]="disabled"
        [minFractionDigits]="commonBindingDataService.getCurrentCurrencyAndLocaleData().maxFractionDigits"
        [maxFractionDigits]="commonBindingDataService.getCurrentCurrencyAndLocaleData().maxFractionDigits"
      >
      </p-inputNumber>
      <small *ngIf="validateInput(formCtrlName)" id="{{ formCtrlName }}-help" class="small-error p-error block"
        ><span translate>{{ label | translate }} </span> {{ 'isRequired' | translate }}</small
      >
    </span>

    <!-- Input type number with decimal for unit -->
    <span *ngIf="inputType === 'unit-number'">
      <p-inputNumber
        #Input
        [placeholder]="placeholder | translate"
        id="{{ formCtrlName }}"
        [formControlName]="formCtrlName"
        inputId="locale-us"
        [min]="min"
        mode="decimal"
        [minFractionDigits]="2"
        (onInput)="onChange($event)"
        [ngClass]="validateError(formCtrlName)"
        [locale]="locale"
        [disabled]="disabled"
        [maxFractionDigits]="2"
      >
      </p-inputNumber>
      <small *ngIf="validateInput(formCtrlName)" id="{{ formCtrlName }}-help" class="small-error p-error block"
        ><span translate>{{ label | translate }} </span> {{ 'isRequired' | translate }}</small
      >
    </span>

    <span *ngIf="inputType === 'text'">
      <input
        #Input
        [placeholder]="placeholder | translate"
        id="{{ formCtrlName }}"
        (keypress)="noSpaceAtStart($event)"
        [formControlName]="formCtrlName"
        type="text"
        (input)="onChange($event)"
        class="input-text"
        [ngClass]="validateError(formCtrlName)"
        pInputText
      />
      <small
        *ngIf="
          parent.get(formCtrlName).hasError('required') && (parent.controls[formCtrlName].dirty || parent.controls[formCtrlName].touched)
        "
        id="{{ formCtrlName }}-help"
        class="small-error p-error block"
        ><span translate>{{ label | translate }} </span> {{ 'isRequired' | translate }}</small
      >
      <small *ngIf="parent.get(formCtrlName).hasError('maxlength')" id="{{ formCtrlName }}-help" class="small-error p-error block"
        ><span translate>max_length_25char </span></small
      >

      <small *ngIf="parent.get(formCtrlName).hasError('pattern')" id="{{ formCtrlName }}-help" class="small-error p-error block"
        ><span translate>{{ 'specialCharacter' | translate }} {{ label | translate }} </span></small
      >

      <small
        *ngIf="parent.get(formCtrlName).hasError('min') || parent.get(formCtrlName).hasError('max')"
        id="{{ formCtrlName }}-help"
        class="small-error p-error block"
      >
        <span translate>percentage_error</span>
      </small>
    </span>

    <span *ngIf="inputType === 'description'">
      <input
        #Input
        [placeholder]="placeholder | translate"
        id="{{ formCtrlName }}"
        (keypress)="noSpaceAtStart($event)"
        [formControlName]="formCtrlName"
        type="text"
        (input)="onChange($event)"
        class="input-text"
        [ngClass]="validateError(formCtrlName)"
        pInputText
      />
      <small
        *ngIf="
          parent.get(formCtrlName).hasError('required') && (parent.controls[formCtrlName].dirty || parent.controls[formCtrlName].touched)
        "
        id="{{ formCtrlName }}-help"
        class="small-error p-error block"
        ><span translate>{{ label | translate }} </span> {{ 'isRequired' | translate }}</small
      >
      <small *ngIf="parent.get(formCtrlName).hasError('maxlength')" id="{{ formCtrlName }}-help" class="small-error p-error block"
        ><span translate>max_length_300char </span></small
      >
      <small *ngIf="parent.get(formCtrlName).hasError('pattern')" id="{{ formCtrlName }}-help" class="small-error p-error block"
        ><span translate>{{ 'specialCharacter' | translate }} {{ label | translate }} </span></small
      >

      <small
        *ngIf="parent.get(formCtrlName).hasError('min') || parent.get(formCtrlName).hasError('max')"
        id="{{ formCtrlName }}-help"
        class="small-error p-error block"
      >
        <span translate>percentage_error</span>
      </small>
    </span>

    <!-- Category Autocomplete -->
    <span class="p-input-icon-right" *ngIf="inputType === 'category-autocomplete'">
      <i class="pi pi-search z-1"></i>
      <p-autoComplete
        #Input
        id="{{ formCtrlName }}"
        [formControlName]="formCtrlName"
        [suggestions]="arrayPayload"
        (completeMethod)="onAutoComplete($event)"
        [field]="field"
        [multiple]="multiple"
        [ngClass]="validateError(formCtrlName)"
        [showEmptyMessage]="true"
        [forceSelection]="true"
        (onSelect)="onChange($event)"
        (onUnselect)="onUnselect($event)"
      >
        <ng-template let-element pTemplate="item">
          <div class="autocomplete-wrapper">
            <span class="bitmap">
              <img src="{{ element.imageUrl }}" class="bitmap" alt="{{ element.name }}" />
            </span>
            <span class="autocomplete-wrapper-text"> {{ element.name }} </span>
            <!-- <span class="autocomplete-wrapper-fwd"> <em class="pi pi-angle-right"></em></span>
            <span class="autocomplete-wrapper-text"> {{ element.label }} </span> -->
          </div>
        </ng-template>
      </p-autoComplete>
      <small *ngIf="validateInput(formCtrlName)" id="{{ formCtrlName }}-help" class="small-error p-error block"
        ><span translate>{{ label | translate }} </span> {{ 'isRequired' | translate }}</small
      >
    </span>

    <!-- Autocomplete -->
    <span class="p-input-icon-right" *ngIf="inputType === 'autocomplete'">
      <i class="pi pi-search z-1"></i>
      <p-autoComplete
        #Input
        [formControlName]="formCtrlName"
        [suggestions]="arrayPayloadAuto"
        (completeMethod)="onAutoComplete($event)"
        (onSelect)="onChange($event)"
        (onUnselect)="onUnselect($event)"
        [field]="field"
        [forceSelection]="true"
        [showEmptyMessage]="true"
        [ngClass]="validateError(formCtrlName)"
        [multiple]="multiple"
      >
      </p-autoComplete>
      <small *ngIf="validateInput(formCtrlName)" id="{{ formCtrlName }}-help" class="small-error p-error block"
        ><span translate>{{ errorMessage || label | translate }} </span> {{ 'isRequired' | translate }}</small
      >
    </span>

    <!-- Dropdown -->
    <span class="p-fluid" *ngIf="inputType === 'dropdown'">
      <p-dropdown
        #Input
        [options]="arrayPayload"
        [formControlName]="formCtrlName"
        [optionLabel]="optionLabel"
        [optionValue]="optionValue"
        [placeholder]="placeholder | translate"
        autoDisplayFirst="false"
        (onChange)="onChange($event)"
        [ngClass]="validateDropdownError(formCtrlName)"
        [disabled]="disabled"
      >
      </p-dropdown>
      <small *ngIf="validateDropdownInput(formCtrlName)" id="{{ formCtrlName }}-help" class="small-error p-error block">
        <span translate>{{ label | translate }}</span> {{ 'isRequired' | translate }}</small
      >
    </span>

    <!-- textarea -->
    <span class="p-fluid" *ngIf="inputType === 'textarea'">
      <textarea
        #Input
        pInputTextarea
        [formControlName]="formCtrlName"
        [rows]="rows"
        [maxlength]="maxlength"
        (keypress)="noSpaceAtStart($event)"
        [ngClass]="validateError(formCtrlName)"
      ></textarea>
      <small *ngIf="validateInput(formCtrlName)" id="{{ formCtrlName }}-help" class="small-error p-error block"
        ><span translate>{{ label | translate }} </span> {{ 'isRequired' | translate }}</small
      >
    </span>

    <!-- chips  -->
    <span class="p-fluid" *ngIf="inputType === 'chips'">
      <p-chips
        #Input
        [formControlName]="formCtrlName"
        (onUnselect)="onUnselect($event)"
        [ngClass]="validateError(formCtrlName)"
        [separator]="separator"
      ></p-chips>
      <small *ngIf="validateInput(formCtrlName)" id="{{ formCtrlName }}-help" class="small-error p-error block"
        ><span translate>{{ label | translate }} </span> {{ 'isRequired' | translate }}</small
      >
    </span>

    <span class="p-fluid" *ngIf="inputType === 'input-group'">
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon" *ngIf="inputGroupIcon === 'start'" (click)="click($event)"
          ><i class="{{ inputGroupIconClass }}"></i
        ></span>
        <input type="text" pInputText [formControlName]="formCtrlName" [placeholder]="placeholder | translate" />
        <span class="p-inputgroup-addon" *ngIf="inputGroupIcon === 'end'" (click)="click($event)"
          ><i class="{{ inputGroupIconClass }}"></i
        ></span>
      </div>
      <small *ngIf="validateInput(formCtrlName)" id="{{ formCtrlName }}-help" class="small-error p-error block"
        >{{ formCtrlName }} {{ 'isRequired' | translate }}</small
      >
    </span>

    <span class="p-fluid" *ngIf="inputType === 'multiselect'">
      <p-multiSelect
        [options]="arrayPayload"
        [formControlName]="formCtrlName"
        (onChange)="onChange($event)"
        [placeholder]="placeholder | translate"
        [ngClass]="validateDropdownError(formCtrlName)"
      ></p-multiSelect>
      <small *ngIf="validateDropdownInput(formCtrlName)" id="{{ formCtrlName }}-help" class="small-error p-error block">
        <span translate>{{ label | translate }}</span> {{ 'isRequired' | translate }}</small
      >
    </span>

    <span class="p-fluid" *ngIf="inputType === 'restricted_multiselect'">
      <p-multiSelect
        [options]="arrayPayload"
        [formControlName]="formCtrlName"
        (onChange)="onChange($event)"
        [placeholder]="placeholder | translate"
        [ngClass]="validateDropdownError(formCtrlName)"
        [selectionLimit]="selectionLimit"
        [optionLabel]="optionLabel"
        [optionValue]="optionValue"
        [optionDisabled]="optionDisabled"
      ></p-multiSelect>
      <small *ngIf="validateDropdownInput(formCtrlName)" id="{{ formCtrlName }}-help" class="small-error p-error block">
        <span translate>{{ label | translate }}</span> {{ 'isRequired' | translate }}</small
      >
    </span>
  </div>
</div>
