import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { RestApiService } from '../../shared/services/rest-api.service';
import { AttributesService } from './attributes.service';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  private baseUrl: string;
  constructor(private restApiService: RestApiService, private attributesService: AttributesService) {
    this.baseUrl = environment.basePath;
  }

  saveSeoDetails(productId: string, payload): Observable<any> {
    return this.attributesService.addProductAttribute(productId, payload);
  }

  saveSizeGuide(productId: string, payload): Observable<any> {
    return this.attributesService.addSizeGuide(productId, payload);
  }
}
