<div class="field" [formGroup]="parent">
  <div class="label-text">
    <label translate for="{{ formCtrlName }}" class="block" translate>
      {{ label }}
      <span translate *ngIf="bracketLabel"> <span>(</span> {{ bracketLabel }} <span>)</span> </span>
      <span *ngIf="isRequired" class="required-star"> * </span>
    </label>
  </div>

  <p-editor [formControlName]="formCtrlName" (input)="onChange($event)" [style]="{ 'min-height': '100px', height }"></p-editor>
  <!-- <small id="username2-help" class="p-error block">Username is not available.</small> -->
</div>
