<div id="header-company-logo" class="company-logo">
  <img src="/assets/images/logoPallod.png" width="10px" />
  <!-- <h3>MOBISOFT</h3> -->
</div>
<p-panelMenu [multiple]="false" id="home-sidebar-panel-menu" class="sidebar-panel-menu" [model]="items"></p-panelMenu>

<p-confirmDialog
  id="session-confirmDialog-ok"
  [header]="'Confirmation' | translate"
  acceptIcon="none"
  rejectIcon="none"
  acceptButtonStyleClass="pri-btn-dialog"
  rejectButtonStyleClass="p-button-outlined confirmDialog-cancel-btn"
  [rejectLabel]="'cancel' | translate"
  [acceptLabel]="'ok' | translate"
  defaultFocus="none"
>
</p-confirmDialog>
