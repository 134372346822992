import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControlName, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorComponent implements OnInit {
  @Input() label: any;
  @Input() parent: FormGroup;
  @Input() formCtrlName: FormControlName;
  @Output() onValueChange = new EventEmitter<any>();
  @Input() bracketLabel;
  @Input() isRequired = false;
  @Input() height = '100px';
  text: string;

  constructor() {}

  ngOnInit(): void {
    this.parent.controls[`${this.formCtrlName}`].valueChanges.subscribe((ele) => {});
  }

  onChange(changes) {
    this.onValueChange.emit(changes);
  }
}
