<div class="signin-box-container grid">
  <div class="signin-box-inner-container">
    <div class="signin-box col-5">
      <div class="company-logo" data-cy="signIn-company-logo">
        <img width="200" src="/assets/images/logoPallod.png" />
      </div>
      <p-card class="signin-card">
        <div *ngIf="isVisibleForgetPassword">
          <form [formGroup]="forgetPasswordForm">
            <div class="form-container grid">
              <div class="header" translate>forgetPassword</div>
              <div class="form-field col-12 col-nogutter">
                <div class="form-label">
                  <label for="email" translate>enterEmail <span class="required-star">*</span></label>
                </div>
                <div class="form-control">
                  <input
                    formControlName="email"
                    class="full-width"
                    type="text"
                    pInputText
                    data-cy="signIn-email"
                    [placeholder]="'enterEmail' | translate"
                  />
                </div>
                <div
                  class="form-errors"
                  *ngIf="
                    forgetPasswordForm.controls.email.errors &&
                    (forgetPasswordForm.controls.email.dirty || forgetPasswordForm.controls.email.touched)
                  "
                >
                  <small [hidden]="!forgetPasswordForm.controls.email.errors.required" class="small-error p-error block" translate
                    >email_required</small
                  >
                </div>
              </div>
              <div class="form-buttons col-12 col-nogutter">
                <button
                  class="loginBtn col-12"
                  pButton
                  [label]="'sendOTP' | translate"
                  type="button"
                  data-cy="signIn-button-login"
                  [disabled]="!forgetPasswordForm.valid"
                  (click)="sendOTP()"
                ></button>
              </div>
            </div>
          </form>
        </div>
        <div *ngIf="isVisibleOTP">
          <form [formGroup]="otpForm">
            <div class="form-container grid">
              <div class="header text-center col-12" translate>otp</div>
              <div class="col-12">
                <small class="text-center" translate>otpSent</small>
              </div>
              <div class="text-center enterOTP col-12 mt-2" translate>enterOTP</div>
              <div *ngIf="visibleTimer" class="text-center col-12 colorBlue font-semibold text-lg">
                {{ displayTime$ | async }}
              </div>
              <div class="form-field col-12 col-nogutter">
                <div class="grid form-control">
                  <div class="col-2">
                    <input
                      #otp0
                      formControlName="input0"
                      mode="decimal"
                      class="full-width otpBox"
                      type="text"
                      (keypress)="numberOnly($event)"
                      (keyup)="otpController($event, otp1, '')"
                      pInputText
                      maxlength="1"
                    />
                  </div>
                  <div class="col-2">
                    <input
                      #otp1
                      formControlName="input1"
                      mode="decimal"
                      class="full-width otpBox"
                      type="text"
                      (keypress)="numberOnly($event)"
                      (keyup)="otpController($event, otp2, otp0)"
                      pInputText
                      maxlength="1"
                    />
                  </div>
                  <div class="col-2">
                    <input
                      #otp2
                      formControlName="input2"
                      mode="decimal"
                      class="full-width otpBox"
                      type="text"
                      (keypress)="numberOnly($event)"
                      (keyup)="otpController($event, otp3, otp1)"
                      pInputText
                      maxlength="1"
                    />
                  </div>
                  <div class="col-2">
                    <input
                      #otp3
                      formControlName="input3"
                      mode="decimal"
                      class="full-width otpBox"
                      type="text"
                      (keypress)="numberOnly($event)"
                      (keyup)="otpController($event, otp4, otp2)"
                      pInputText
                      maxlength="1"
                    />
                  </div>
                  <div class="col-2">
                    <input
                      #otp4
                      formControlName="input4"
                      mode="decimal"
                      class="full-width otpBox"
                      type="text"
                      (keypress)="numberOnly($event)"
                      (keyup)="otpController($event, otp5, otp3)"
                      pInputText
                      maxlength="1"
                    />
                  </div>
                  <div class="col-2">
                    <input
                      #otp5
                      formControlName="input5"
                      mode="decimal"
                      class="full-width otpBox"
                      type="text"
                      (keypress)="numberOnly($event)"
                      (keyup)="otpController($event, otp5, otp4)"
                      pInputText
                      maxlength="1"
                    />
                  </div>
                </div>
              </div>
              <div class="text-center col-12 mt-3 colorBlue" translate>
                <span translate (click)="resendOTP()" [disabled]="!visibleTimer">resendOTP</span>
              </div>
              <div class="form-buttons col-12 col-nogutter">
                <button
                  class="loginBtn col-12"
                  pButton
                  [label]="'verifyOTP' | translate"
                  type="button"
                  data-cy="signIn-button-login"
                  [disabled]="!otpForm.valid"
                  (click)="verifyOTP()"
                ></button>
              </div>
            </div>
          </form>
        </div>
        <div *ngIf="isVisibleNewPassword">
          <form [formGroup]="newPasswordForm">
            <div class="form-container grid">
              <div class="header" translate>resetPassword</div>
              <div class="form-field col-12 col-nogutter">
                <div class="form-label">
                  <label for="passwd" translate>newPassword</label>
                </div>
                <div class="form-control">
                  <input
                    pPassword
                    formControlName="password"
                    type="{{ passwordType }}"
                    (paste)="(false)"
                    (copy)="(false)"
                    (cut)="(false)"
                    class="full-width"
                    [feedback]="false"
                    data-cy="signIn-password"
                    [placeholder]="'enterPassword' | translate"
                    styleClass="p-password p-component p-input-icon-right"
                  />
                  <span (click)="toggleEye()">
                    <em [ngClass]="isEyeOpen ? 'pi pi-eye' : 'pi pi-eye-slash'" id="togglePassword"></em>
                  </span>
                </div>
                <div
                  class="form-errors"
                  *ngIf="
                    newPasswordForm.controls.password.errors &&
                    (newPasswordForm.controls.password.dirty || newPasswordForm.controls.password.touched)
                  "
                >
                  <div [hidden]="!newPasswordForm.controls.password.errors.required" translate="">requiredPassword</div>
                  <small *ngIf="newPasswordForm.get('password').errors.confirmPasswordValidator" class="small-error p-error block"
                    ><span translate>requiredPassword </span></small
                  >
                </div>
              </div>
              <div class="form-field col-12 col-nogutter">
                <div class="form-label">
                  <label for="passwd" translate>reEnterPassword</label>
                </div>
                <div class="form-control">
                  <input
                    pPassword
                    formControlName="confirmPassword"
                    type="{{ repasswordType }}"
                    (paste)="(false)"
                    (copy)="(false)"
                    (cut)="(false)"
                    class="full-width"
                    [feedback]="false"
                    data-cy="signIn-password"
                    [placeholder]="'enterPassword' | translate"
                    styleClass="p-password p-component p-input-icon-right"
                  />
                  <span (click)="reToggleEye()">
                    <em [ngClass]="isEyeOpen2 ? 'pi pi-eye' : 'pi pi-eye-slash'" id="togglePassword"></em>
                  </span>
                </div>
                <div
                  class="form-errors"
                  *ngIf="
                    newPasswordForm.controls.confirmPassword.errors &&
                    (newPasswordForm.controls.confirmPassword.dirty || newPasswordForm.controls.confirmPassword.touched)
                  "
                >
                  <div [hidden]="!newPasswordForm.controls.confirmPassword.errors.required" translate="">requiredPassword</div>
                  <small *ngIf="newPasswordForm.get('confirmPassword').errors.confirmPasswordValidator" class="small-error p-error block"
                    ><span translate>notMatchedPassword </span></small
                  >
                </div>
              </div>
              <div class="form-buttons col-12 col-nogutter">
                <button
                  class="loginBtn col-12"
                  pButton
                  [label]="'resetPassword' | translate"
                  type="button"
                  data-cy="signIn-button-login"
                  [disabled]="!newPasswordForm.valid"
                  (click)="resetPassword()"
                ></button>
              </div>
            </div>
          </form>
        </div>
      </p-card>
    </div>
  </div>
</div>
