import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AppSettings } from '../../app.settings';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { SidebarService } from '../../services/sidebar.service';
import { StorageService } from '../../services/storage.service';
import { UiService } from '../../services/ui.service';
import { UserCommonService } from '../../services/user-common.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  // items: MenuItem[];
  subscription: Subscription;
  menuSubscription: Subscription;

  user;
  items: MenuItem[];
  isSidebarClose = false;
  constructor(
    private storageService: StorageService,
    private commonService: CommonBindingDataService,
    private sidebarService: SidebarService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private userCommandService: UserCommonService,
    private userRoleService: UserCommonService,
    private uiService: UiService
  ) {}

  ngOnInit() {
    // this.initSidebarItems();
    this.isSidebarClose = document.getElementsByTagName('body')[0].classList.contains('closed');

    //sidebar toggle Subscription code.
    this.menuSubscription = this.uiService.sidebarToggledEvent.subscribe((state: string) => {
      this.isSidebarClose = document.getElementsByTagName('body')[0].classList.contains('closed');
      this.checkRole();
    });

    this.checkRole();
  }

  checkRole() {
    const roleId = this.storageService.getItem('roleId');
    if (AppSettings.STORE_MANAGER_ACCESS === roleId) {
      this.createStoreManagerSidebar();
    } else {
      this.createAdminSidebar();
    }
  }

  createAdminSidebar() {
    this.items = [
      {
        label: this.commonService.getLabel('sidebar_lbl_dashboard'),
        // tooltipOptions: this.isSidebarClose ? { tooltipLabel: this.commonService.getLabel('sidebar_lbl_dashboard'),
        tooltipOptions: this.isSidebarClose ? { tooltipLabel: this.commonService.getLabel('sidebar_lbl_dashboard') } : {},
        icon: 'pi pi-microsoft',
        routerLink: ['dashboard'],
        expanded: this.isActive('dashboard'),
      },
      {
        label: this.commonService.getLabel('sidebar_lbl_product'),
        tooltipOptions: this.isSidebarClose ? { tooltipLabel: this.commonService.getLabel('sidebar_lbl_product') } : {},
        icon: 'pi pi-shopping-cart',
        expanded: this.isActive('products/'),
        items: [
          {
            label: this.commonService.getLabel('sidebarLabelProductManagement'),
            tooltipOptions: this.isSidebarClose ? { tooltipLabel: this.commonService.getLabel('sidebarLabelProductManagement') } : {},
            icon: 'pi pi-tablet',
            routerLink: ['products/product-management'],
            expanded: this.isActive('products/product-management'),
          },
          {
            label: this.commonService.getLabel('sidebarLabelUploadFiles'),
            tooltipOptions: this.isSidebarClose ? { tooltipLabel: this.commonService.getLabel('sidebarLabelUploadFiles') } : {},
            icon: 'pi pi-upload',
            routerLink: ['products/upload-products-management'],
            expanded: this.isActive('products/upload-products-management'),
          },
          {
            label: this.commonService.getLabel('sidebarLabelBrand'),
            tooltipOptions: this.isSidebarClose ? { tooltipLabel: this.commonService.getLabel('sidebarLabelBrand') } : {},
            icon: 'pi pi-box',
            routerLink: ['products/brand'],
            expanded: this.isActive('products/brand'),
          },
          {
            label: this.commonService.getLabel('sidebarLabelAttributeMaster'),
            tooltipOptions: this.isSidebarClose ? { tooltipLabel: this.commonService.getLabel('sidebarLabelAttributeMaster') } : {},
            icon: 'pi pi-file-edit',
            routerLink: ['products/attributes'],
            expanded: this.isActive('products/attributes'),
          },
          {
            label: this.commonService.getLabel('sidebarLabelSizeMaster'),
            tooltipOptions: this.isSidebarClose ? { tooltipLabel: this.commonService.getLabel('sidebarLabelSizeMaster') } : {},
            icon: 'pi pi-file-edit',
            routerLink: ['products/size'],
            expanded: this.isActive('products/size'),
          },
          {
            label: this.commonService.getLabel('sidebarLabelCategory'),
            tooltipOptions: this.isSidebarClose ? { tooltipLabel: this.commonService.getLabel('sidebarLabelCategory') } : {},
            icon: 'pi pi-sitemap',
            routerLink: ['products/category'],
            expanded: this.isActive('products/category'),
          },
          {
            label: this.commonService.getLabel('sidebarLabelInventory'),
            tooltipOptions: this.isSidebarClose ? { tooltipLabel: this.commonService.getLabel('sidebarLabelInventory') } : {},
            icon: 'pi pi-server',
            routerLink: ['products/inventory-management'],
            expanded: this.isActive('products/inventory-management'),
          },
          /*{
            label: this.commonService.getLabel('sidebarLabelSponsoredProducts'),
            tooltipOptions: this.isSidebarClose ? { tooltipLabel: this.commonService.getLabel('sidebarLabelSponsoredProducts') } : {},
            icon: 'pi pi-money-bill',
            routerLink: ['products/sponsored-product'],
            expanded: this.isActive('products/sponsored-product'),
          },*/
        ],
      },
      {
        label: this.commonService.getLabel('sidebar_orders'),
        tooltipOptions: this.isSidebarClose ? { tooltipLabel: this.commonService.getLabel('sidebar_orders') } : {},
        icon: 'pi pi-shopping-bag',
        // routerLink: ['order-list'],
        expanded: this.isActive('order/'),
        items: [
          {
            label: this.commonService.getLabel('sidebar_lbl_orderManagement'),
            tooltipOptions: this.isSidebarClose ? { tooltipLabel: this.commonService.getLabel('sidebar_lbl_orderManagement') } : {},
            icon: 'pi pi-dollar',
            routerLink: ['order/order-list'],
            expanded: this.isActive('order-list'),
          },
          {
            label: this.commonService.getLabel('sidebar_reports'),
            tooltipOptions: this.isSidebarClose ? { tooltipLabel: this.commonService.getLabel('sidebar_reports') } : {},
            icon: 'pi pi-download',
            routerLink: ['order/order-reports'],
            expanded: this.isActive('order/order-reports'),
          },
        ],
      },
      {
        label: this.commonService.getLabel('sidebar_lbl_admin'),
        tooltipOptions: this.isSidebarClose ? { tooltipLabel: this.commonService.getLabel('sidebar_lbl_admin') } : {},
        icon: 'pi pi-user',
        routerLink: ['admin'],
        expanded: this.isActive('admin'),
      },
      /*{
        label: this.commonService.getLabel('sidebar_lbl_storeManagement'),
        tooltipOptions: this.isSidebarClose ? { tooltipLabel: this.commonService.getLabel('sidebar_lbl_storeManagement') } : {},
        icon: 'pi pi-building',
        routerLink: ['store-management'],
        expanded: this.isActive('store-management'),
      },*/
      {
        label: this.commonService.getLabel('sidebar_lbl_marketing'),
        tooltipOptions: this.isSidebarClose ? { tooltipLabel: this.commonService.getLabel('sidebar_lbl_marketing') } : {},
        icon: 'pi pi-tags',
        expanded: this.isActive('marketing/'),
        items: [
          {
            label: this.commonService.getLabel('sidebar_lbl_banner'),
            tooltipOptions: this.isSidebarClose ? { tooltipLabel: this.commonService.getLabel('sidebar_lbl_banner') } : {},
            icon: 'pi pi-images',
            routerLink: ['marketing/banner-list'],
          },
          /*{
            label: this.commonService.getLabel('sidebar_lbl_couponCode'),
            tooltipOptions: this.isSidebarClose ? { tooltipLabel: this.commonService.getLabel('sidebar_lbl_couponCode') } : {},
            icon: 'pi pi-verified',
            routerLink: ['marketing/coupon-code'],
            expanded: this.isActive('marketing/coupon-code'),
          },
          {
            label: this.commonService.getLabel('sidebar_lbl_comboOffer'),
            tooltipOptions: this.isSidebarClose ? { tooltipLabel: this.commonService.getLabel('sidebar_lbl_comboOffer') } : {},
            icon: 'pi pi-comments',
            routerLink: ['marketing/combo-offer'],
            expanded: this.isActive('marketing/combo-offer'),
          },*/
          {
            label: this.commonService.getLabel('sidebar_lbl_notification'),
            icon: 'pi pi-volume-down',
            tooltipOptions: this.isSidebarClose ? { tooltipLabel: this.commonService.getLabel('sidebar_lbl_notification') } : {},
            routerLink: ['marketing/notification'],
            expanded: this.isActive('marketing/notification'),
          },
        ],
      },
      {
        label: this.commonService.getLabel('sidebar_lbl_customerManagement'),
        tooltipOptions: this.isSidebarClose ? { tooltipLabel: this.commonService.getLabel('sidebar_lbl_customerManagement') } : {},
        icon: 'pi pi-users',
        routerLink: ['customer-management'],
        expanded: this.isActive('customer-management'),
      },
      {
        label: this.commonService.getLabel('sidebar_lbl_customerStories'),
        tooltipOptions: this.isSidebarClose ? { tooltipLabel: this.commonService.getLabel('sidebar_lbl_customerStories') } : {},
        icon: 'pi pi-users',
        routerLink: ['customer-stories'],
        expanded: this.isActive('customer-stories'),
      },
      {
        label: this.commonService.getLabel('sidebar_lbl_logout'),
        tooltipOptions: this.isSidebarClose ? { tooltipLabel: this.commonService.getLabel('sidebar_lbl_logout') } : {},
        icon: 'pi pi-sign-out',
        //routerLink:['signin'],
        command: (e) => {
          this.logoutCall();
        },
      },
    ];
  }

  createStoreManagerSidebar() {
    this.items = [
      {
        label: this.commonService.getLabel('sidebar_lbl_orderManagement'),
        tooltipOptions: this.isSidebarClose ? { tooltipLabel: this.commonService.getLabel('sidebar_lbl_orderManagement') } : {},
        icon: 'pi pi-shopping-bag',
        routerLink: ['order/order-list'],
        expanded: this.isActive('order/order-list'),
      },
      {
        label: this.commonService.getLabel('sidebar_lbl_customerManagement'),
        tooltipOptions: this.isSidebarClose ? { tooltipLabel: this.commonService.getLabel('sidebar_lbl_customerManagement') } : {},
        icon: 'pi pi-users',
        routerLink: ['customer-management'],
        expanded: this.isActive('customer-management'),
      },
      {
        label: this.commonService.getLabel('sidebar_lbl_customerStories'),
        tooltipOptions: this.isSidebarClose ? { tooltipLabel: this.commonService.getLabel('sidebar_lbl_customerStories') } : {},
        icon: 'pi pi-users',
        routerLink: ['customer-stories'],
        expanded: this.isActive('customer-stories'),
      },
      {
        label: this.commonService.getLabel('sidebarLabelInventory'),
        tooltipOptions: this.isSidebarClose ? { tooltipLabel: this.commonService.getLabel('sidebarLabelInventory') } : {},
        icon: 'pi pi-server',
        routerLink: ['products/inventory-management'],
        expanded: this.isActive('products/inventory-management'),
      },
      {
        label: this.commonService.getLabel('sidebar_lbl_logout'),
        tooltipOptions: this.isSidebarClose ? { tooltipLabel: this.commonService.getLabel('sidebar_lbl_logout') } : {},
        icon: 'pi pi-sign-out',
        command: (e) => {
          this.logoutCall();
        },
      },
    ];
  }

  checkActiveState(givenLink) {
    if (this.router.url.indexOf(givenLink) === -1) {
      return false;
    } else {
      return true;
    }
  }

  logoutCall() {
    this.confirmationService.confirm({
      message: this.commonService.getLabel('msg_logout'),
      accept: () => {
        this.logout();
      },
    });
  }

  logout() {
    this.userCommandService.signOut().subscribe((results) => {
      this.storageService.removeAll();
      this.router.navigate(['/signin']).then(() => {
        location.reload();
      });
    });
  }

  initSidebarItems() {
    this.items = [];
    this.user = this.storageService.getItem(AppSettings.USER_DETAILS);
    this.subscription = this.sidebarService.sideState.subscribe((state: any) => {
      this.items = state;
      this.items = this.removeDuplicates(this.items, 'label');
    });
    if (this.items.length <= 0) {
      if (this.user !== null && this.user !== undefined) {
        this.items = this.getMenu();
      }
    }
    this.manageActiveStatus();
  }

  removeDuplicates(myArr, prop) {
    return myArr.filter((obj, pos, arr) => arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos);
  }

  getMenu() {
    return this.sidebarService.adminSideBar;
  }

  private manageActiveStatus() {
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];
      if (item.routerLink) {
        if (item.routerLink.length >= 1 && document.location.pathname === item.routerLink[0]) {
          item.expanded = true;
        }
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  isActive(event) {
    return this.router.url.includes(event);
  }
}
