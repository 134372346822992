import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './modules/shared/components/layout/layout.component';
import { AuthenticationValidationService } from './modules/shared/services/authentication-validation.service';
import { PasswordManagementComponent } from './pages/password-management/password-management.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
export const routes: Routes = [
  {
    path: 'signin',
    pathMatch: 'full',
    component: SignInComponent,
  },
  {
    path: 'signup',
    pathMatch: 'full',
    component: SignUpComponent,
    data: { title: '', breadCrumbs: '' },
  },
  {
    path: 'forgot-password',
    pathMatch: 'full',
    component: PasswordManagementComponent,
    data: { title: '', breadCrumbs: '' },
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthenticationValidationService],
    children: [
      {
        path: 'customer-management',
        loadChildren: () => import('./modules/customer/customer.module').then((mod) => mod.CustomerModule),
        //data: { preload: true }
        canActivate: [AuthenticationValidationService],
      },
      {
        path: 'customer-stories',
        loadChildren: () => import('./modules/customer-stories/customer-stories.module').then((mod) => mod.CustomerStoriesModule),
        canActivate: [AuthenticationValidationService],
      },
      {
        path: 'admin',
        loadChildren: () => import('./modules/admin/admin.module').then((mod) => mod.AdminModule),
        canActivate: [AuthenticationValidationService],
      },
      {
        path: 'products',
        loadChildren: () => import('./modules/product/product.module').then((mod) => mod.ProductModule),
        canActivate: [AuthenticationValidationService],
      },
      {
        path: 'store-management',
        loadChildren: () => import('./modules/store/store.module').then((mod) => mod.StoreModule),
        canActivate: [AuthenticationValidationService],
      },
      {
        path: 'order',
        loadChildren: () => import('./modules/orders/orders.module').then((m) => m.OrdersModule),
        canActivate: [AuthenticationValidationService],
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [AuthenticationValidationService],
      },
      {
        path: 'marketing',
        loadChildren: () => import('./modules/marketing/marketing.module').then((m) => m.MarketingModule),
        canActivate: [AuthenticationValidationService],
      },
      {
        path: '**',
        redirectTo: '/admin',
      },
    ],
  },
  { path: 'store', loadChildren: () => import('./modules/store/store.module').then((m) => m.StoreModule) },
  { path: 'marketing', loadChildren: () => import('./modules/marketing/marketing.module').then((m) => m.MarketingModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
