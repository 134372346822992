/* eslint-disable @typescript-eslint/naming-convention */

import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
import { MarketingService } from '../../../marketing/services/marketing.service';
import { InventoryService } from '../../../product/services/inventory.service';
import { AppSettings } from '../../app.settings';

import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { StorageService } from '../../services/storage.service';
import * as utils from '../../utility-functions/utils';
import { ChartService } from '../chart/chart.service';
import { LoaderService } from '../loader/loader.service';
import { UiService } from './../../services/ui.service';
import { GridModel } from './grid-model';
import { GridService } from './grid.service';
@Component({
  selector: 'app-mi-grid-v2',
  providers: [GridService],
  templateUrl: './mi-grid.component.html',
})
export class MiGridComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  get miGridTitle() {
    throw new Error('Attribute "miGridTitle" is required');
  }
  set miGridTitle(value: any) {
    Object.defineProperty(this, 'miGridTitle', {
      value,
      writable: true,
      configurable: true,
    });
  }
  @ViewChild('primeTable') primeTable: Table;
  @Input() viewDetailsRequired: boolean;
  @Input() reloadRequired: boolean;
  @Input() miGridTitleFlag = true;
  @Input() miGridTitleClass;
  @Output() rowClickedEvent = new EventEmitter<any>();
  @Output() actionBtnClickedEvent = new EventEmitter<any>();
  @Output() getGridReference: EventEmitter<any> = new EventEmitter();
  @Output() onMoreButtonClicked: EventEmitter<any> = new EventEmitter();
  @Input() url = '';
  @Input() statusUrl: string;
  @Input() deleteUrl: string;
  @Input() statusReq: any;
  @Input() searchBar: string;
  @Input() searchBarClass = ['searchbar', 'mr-3'];
  @Input() exportButton: string;
  @Input() rowSelection = 'single';
  @Input() printUrl: any = '';
  @Input() exportUrl: any = '';
  @Input() columnDefs: any[] = [];
  @Input() actionButtonsDefs: any[] = [];
  @Input() pagination: any = true;
  @Input() paginationPageSize: any = this.commonService.getTableRowLength();
  @Input() paginationStartPage: any = 0;
  @Input() defaultSortField = '';
  @Input() divClass = '';
  @Input() actionSwitch: any = [];
  @Input() overlayLoadingTemplate: any =
    '<span class="ag-overlay-loading-center" "style=background:red">' + 'Please wait while your rows are loading</span>';
  @Input() buttonLabel: any;
  @Input() exportButtonHeader: any;
  @Input() exportFileType: any;
  @Input() buttonRouterLink: any = 'add';
  @Input() dropdownOptions: any[];
  @Input() dropdownSelection: {};
  @Input() dropdownPlaceholder: any;
  @Input() dropdownOptionLabel: any;
  @Input() dropdownClass = ['bulk-dropdown'];

  @Input() dropdown2Class = ['bulk-dropdown'];
  @Input() dropdown2Options: any[];
  @Input() dropdown2Selection: {};
  @Input() dropdown2Placeholder: any;
  @Input() dropdown2OptionLabel: any;

  @Input() buttonDivClass = ['grid', 'justify-content-end', 'col'];
  @Input() exportButtonLabel: any;
  @Input() isBinary = false;
  @Input() isCheckBoxVisible: any = true;
  @Input() edit: any[] = [];
  @Input() isSearchBarVisible = true;
  @Input() isStoreSearchBarVisible = false;
  @Input() dropdownLabel = '';
  @Output() onExportButtonClick = new EventEmitter();
  @Input() extraFields: any = {};
  @Output() listSize: EventEmitter<any> = new EventEmitter<any>();
  @Input() processButtonLabel;
  @Input() refreshButtonIconClass = '';
  @Input() refreshButtonClass = ['mr-3'];
  @Input() uploadedFileData = '';
  @Input() isEnableProcessButton = true;
  @Input() isDateField = false;
  @Input() dateFieldType = 'single';
  @Input() isStatusDropdown = false;
  @Input() statusDropdownOptions;
  @Output() onProcessClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() dataSource = [];
  @Output() onClickOfViewDetails = new EventEmitter();
  @Input() filterInViewDetails: boolean;
  @Output() filterDropdownChange = new EventEmitter();
  @Input() datePlaceholder;
  @Input() gridHeadMarginClass = 'mb-5';
  @Input() isDownloadBtn = false;
  @Input() isEnableProcessButtonsWithIcons = false;
  @Input() reloadLabel = false;
  @Input() titleClass;
  @Input() componentName = '';
  @Input() headerTitleId = '';
  @Input() headerViewDetailsId = '';
  @Input() headerRefreshListId = '';
  @Input() headerSearchValueId = '';
  @Input() headerAddProductButton = '';
  @Input() headertableData = '';
  @Input() searchValueProductID = '';
  @Input() searchValueStoreID = '';
  @Input() searchButtonByProductAndStoreID = '';
  @Input() headerStatusDropdownID = '';
  @Input() headerDatepickerID = '';
  @Input() startDateCouponCodeID = '';
  @Input() endDateCouponCodeID = '';
  @Input() uploadPreProcessActionBtn = '';
  @Input() uploadPostProcessActionBtn = '';
  @Input() uploadProcessActionBtn = '';
  @Input() uploadedFileActionBtn = '';
  @Input() evaluatedFileActionBtn = '';
  @Input() processedFileActionBtn = '';
  @Input() defaultSwitchValue = false;

  uploadProductImageComponent = AppSettings.COMPONENT_UPLOAD_PRODUCT_IMAGES;
  enableResizer = 'false';
  subscription: Subscription;
  rowData: any[] = [];
  search = '';
  selectedRow = '';
  model: GridModel;
  totalRecords = 0;
  sortF = '';
  sortO = '';
  offset = 0;
  setRowLength = this.commonService.getTableRowLength();
  searchText = '';
  searchTextByproduct = '';
  searchTextByStore = '';
  first = 0;
  last = 10;
  selectedRecords: any = [];
  statusColArray = [
    'onlineStatus',
    'visibleToCartStr',
    'status',
    'visibility',
    'availableStatus',
    'statusValue',
    'available',
    'availableStr',
    'activeStr',
  ];
  textColArray = AppSettings.TEXT_COL_ARRAY;
  dateColArray = AppSettings.DATE_ARRAY;
  dateFormat = AppSettings.DATE_FORMAT;
  dateTableFormat = AppSettings.DATE_FORMAT_TABLE;
  dateTableFormatShort = AppSettings.DATE_FORMAT_TABLE_SHORT;
  dateTableFormatNoYear = AppSettings.DATE_FORMAT_TABLE_NO_YEAR;

  imageColArray = ['categoryImageUrl', 'imageUrl'];
  selectedCity: any;
  checked: any;
  searchPlaceholder = this.commonService.getLabel('search_lbl');
  searchStorePlaceholder = this.commonService.getLabel('searchByStore_lbl');
  searchSKUPlaceholder = this.commonService.getLabel('searchBySKU_lbl');
  searchValue: any;
  searchValueProduct: any;
  searchValueStore: any;
  startDate: any;
  endDate: any;
  display = false;
  couponSwitchForm: FormGroup;
  stockUpdateForm: FormGroup;
  statusList = [
    { label: this.commonService.getLabel('active'), value: 'true' },
    { label: this.commonService.getLabel('inActive'), value: 'false' },
  ];
  sortDropDown: any;
  switchData: any = '';
  switchObj: any = {};
  currentIndex: any;
  currentSwitchField: string;
  isPromo: boolean;
  dashboardFilterForm: FormGroup;
  monthList: { name: string; value: number }[];
  criteriaList: { name: string; value: number }[];
  categoryList: { name: string; value: number }[];
  orderedFrom: any;
  orderedTo: any;
  defaultToMonth = utils.getDashboardDefaultValues().month[new Date().getMonth()];
  userCountry;
  paginationText;
  searchInputChange = this.debounce((searchText: string) => this.applySearch(searchText));
  displayStockedClicked = false;
  indexOfStock;
  stockData: any;

  constructor(
    private loaderService: LoaderService,
    private uiService: UiService,
    private gridService: GridService,
    public commonService: CommonBindingDataService,
    private confirmationService: ConfirmationService,
    private marketingService: MarketingService,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private inventoryService: InventoryService,
    private commonBindingDataService: CommonBindingDataService,
    public chartService: ChartService
  ) {}

  ngOnInit() {
    this.subscription = this.uiService.sidebarToggledEvent.subscribe((state: string) => {
      // this.refreshList();
    });
    // this.dataSource = [];
    this.totalRecords = this.dataSource.length;
    this.couponSwitchForm = this.formBuilder.group({
      published: ['', Validators.required],
      inactiveReason: ['', Validators.required],
    });

    this.stockUpdateForm = this.formBuilder.group({
      stock: ['', Validators.required],
    });
    if (this.actionSwitch[0]?.field === 'promoCodeEnabled') {
      this.isPromo = true;
      this.currentSwitchField = 'updatePromoStatus';
    } else {
      this.isPromo = false;
      this.currentSwitchField = 'updateComboStatus';
    }
    this.dashboardFilterForm = this.formBuilder.group({
      filterByCategory: [''],
      filterByCriteria: [''],
      filterBySearchInput: [''],
      filterByFromMonth: [''],
      filterByToMonth: [''],
    });
    this.setMonthDropDown();
    this.setCriteriaDropDown();
    this.setCategoryDropDown();
    this.userCountry = utils.getCountryId();
    const showing = this.commonService.getLabel('showing');
    const to = this.commonService.getLabel('To');
    const of = this.commonService.getLabel('of');
    const entries = this.commonService.getLabel('entries');
    this.paginationText = showing + ' {first} ' + to + ' {last} ' + of + ' {totalRecords} ' + entries;
  }
  isCompleted = (value) => value.toLowerCase().includes(AppSettings.status_completed.toLowerCase());
  isDraft = (value) => value.toLowerCase().includes(AppSettings.status_draft.toLowerCase());

  refreshList() {
    if (this.url !== '' && this.userCountry) {
      this.fetchAndLoadData();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.extraFields.limit) {
      this.setRowLength = this.extraFields.limit;
    }
    this.userCountry = utils.getCountryId();
    if (
      this.url === '' &&
      changes?.dataSource?.currentValue?.length > 0 &&
      changes?.dataSource?.previousValue?.length !== changes?.dataSource?.currentValue?.length
    ) {
      this.totalRecords = this.dataSource.length;
      this.loaderService.hide();
      this.listSize.emit(this.totalRecords);
      const data = this.dataSource;
      this.dataSource = [];
      this.filData(data);
    }

    if (changes?.extraFields) {
      this.fetchAndLoadData();
    }
  }

  isDraftImageUpload(value) {
    return value.toLowerCase().includes(AppSettings.status_draft.toLowerCase());
  }

  isEvaluatedImageUpload(value) {
    return value.toLowerCase().includes(AppSettings.status_evaluated.toLowerCase());
  }

  isProcessedFileShow(value) {
    return value.toLowerCase().includes(AppSettings.status_completed.toLowerCase());
  }

  isEvaluatedFileShow(value) {
    return !(
      value.toLowerCase() === AppSettings.status_draft.toLowerCase() ||
      value.toLowerCase() === AppSettings.status_evaluate.toLowerCase() ||
      value.toLowerCase() === AppSettings.status_evaluating.toLowerCase()
    );
  }

  openStock(event, index) {
    this.displayStockedClicked = true;
    this.indexOfStock = index;
    this.stockUpdateForm.patchValue({
      stock: this.stockData?.records[this.indexOfStock]?.stock,
    });
  }

  updateStock() {
    if (this.stockUpdateForm.get('stock').value === null) {
      this.messageService.add({
        severity: 'error',
        key: 'tr',
        summary: 'Required',
        detail: this.commonBindingDataService.getLabel('stockError'),
      });
      return;
    }
    const payload = {
      storeProductId: this.stockData?.records[this.indexOfStock]?.storeProductId,
      productId: this.stockData?.records[this.indexOfStock]?.productId,
      stock: this.stockUpdateForm.get('stock').value,
    };
    this.inventoryService.stockUpdate(payload).subscribe((res: any) => {
      this.messageService.add({
        severity: 'success',
        key: 'tr',
        summary: 'Success',
        detail: this.commonBindingDataService.getLabel('stockUpdateSuccess'),
      });

      this.refreshList();
    });
    this.displayStockedClicked = false;
  }
  hideDialog() {
    this.displayStockedClicked = false;
  }

  fetchAndLoadData() {
    if (!this.url.length) {
      return;
    }
    const extraFields = Object.keys(this.extraFields);
    let params = `offset=${this.offset}&limit=${
      this.setRowLength
    }&languageCode=${utils.getLanguage()}&countryId=${utils.getCountryId()}&sortColumn=${this.sortF}&sortType=${this.sortO}`;

    if (this.searchText) {
      params += `&search=${this.searchText}`;
    }

    if (this.searchTextByproduct) {
      params += `&search=${this.searchTextByproduct}`;
    }

    if (this.searchTextByStore) {
      params += `&searchStore=${this.searchTextByStore}`;
    }

    if (extraFields.length) {
      extraFields.map((ele) => {
        params += `&${ele}=${this.extraFields[ele]}`;
      });
    }
    if (this.orderedFrom) {
      params += `&orderDateFrom=${utils.getCurrentStartOfDay(this.orderedFrom).valueOf()}`;
    }
    if (this.orderedTo) {
      params += `&orderDateTo=${utils.getCurrentEndOfDay(this.orderedTo).valueOf()}`;
    }
    if (this.startDate) {
      params += `&startDate=${utils.getCurrentStartOfDay(this.startDate).valueOf()}`;
    }
    if (this.endDate) {
      params += `&endDate=${utils.getCurrentEndOfDay(this.endDate).valueOf()}`;
    }
    if (this.sortDropDown) {
      params += `&status=${this.sortDropDown}`;
    }
    let buildUrl = this.url;
    if (this.url.lastIndexOf('?') < 0) {
      buildUrl += '?';
    } else {
      buildUrl += '&';
    }
    buildUrl += params;
    this.gridService.loadGridData(buildUrl).subscribe({
      next: (result) => {
        this.loaderService.hide();
        const model = result as GridModel;
        this.stockData = model;
        if (result !== undefined) {
          this.listSize.emit(model.totalRecords);
          this.renderData(model);
        }
      },
      error: (err) => {
        this.listSize.emit([]);
        this.renderData();
      },
    });
  }

  renderData(model?: GridModel) {
    const that = this;
    this.totalRecords = model?.totalRecords ? model?.totalRecords : 0;
    that.dataSource = [];
    if (this.totalRecords > 0) {
      this.filData(model.records);
    }
  }

  filData(records) {
    const that = this;
    records.forEach((e) => {
      that.columnDefs.forEach((c) => {
        if (c && c.cellRenderer && !c.htmlRenderer) {
          e[c.field] = c.cellRenderer(e[c.field]);
        }
        if (c && c.htmlRenderer) {
          e[c.field] = c.htmlRenderer(e);
        }

        if ((c.field === 'status' || c.field === 'visibility') && typeof e[c.field] === 'number') {
          if (c.field === 'visibility') {
            e['checked'] = e[c.field] ? true : '';
          }
          e[c.field] = e[c.field] ? 'Active' : 'Inactive';
        }
        if (c.field === 'values' && e[c.field]) {
          e[c.field] = JSON.parse(e[c.field]);
        }
        if (c.field === 'values' && !e[c.field]) {
          e[c.field] = '--';
        }
        if (c.field === 'toppings' && e[c.field]) {
          e[c.field] = JSON.parse(e[c.field]).join(', ');
        }
        if (c.field === 'toppings' && !e[c.field]) {
          e[c.field] = '--';
        }
        if (c.field === 'type' && e.attributeId) {
          e[c.field] = e[c.field].charAt(0).toUpperCase() + e[c.field].slice(1);
        }
      });
      that.dataSource.push(e);
      this.dataSource = this.dataSource.map((ele, i) => {
        ele = { ...ele, slno: i + 1 };
        return ele;
      });
    });
  }

  public onQuickFilterChanged($event) {
    if ($event.key === 'Enter') {
      this.applySearch(this.search);
    }
  }

  updateUrl(url: string) {
    this.url = url;
    this.refreshList();
  }

  getClassForStatus(statusValue) {
    if (AppSettings.greenArray.includes(statusValue)) {
      return 'green-class';
    } else if (AppSettings.redArray.includes(statusValue)) {
      return 'red-class';
    } else if (AppSettings.blackArray.includes(statusValue)) {
      return 'black-class';
    } else if (AppSettings.orangeArray.includes(statusValue)) {
      return 'orange-class';
    } else if (AppSettings.blueArray.includes(statusValue)) {
      return 'blue-class';
    } else if (AppSettings.yellowArray.includes(statusValue)) {
      return 'yellow-class';
    } else if (AppSettings.brownArray.includes(statusValue)) {
      return 'brown-class';
    }
  }

  loadDatasourceLazy(event: LazyLoadEvent) {
    this.sortF = this.defaultSortField;
    this.offset = event.first;
    this.setRowLength = event.rows;
    this.commonService.setTableRowLength(event.rows);
    if (event.sortField !== undefined) {
      this.sortF = event.sortField;
    }
    this.sortO = event.sortOrder > 0 ? 'asc' : 'desc';
    if (this.url === '') {
      return;
    }
    this.fetchAndLoadData();
  }

  onRowSelect(event) {
    this.rowClickedEvent.emit(this.selectedRecords);
  }

  onRowUnselect(event) {}

  actionBtnClick(data: any, action: string) {
    const obj = { row: data, class: action };
    this.actionBtnClickedEvent.emit(obj);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getSelectedRecords() {
    return this.selectedRecords;
  }

  //this is custom debounce function
  debounce(func, timeout = 1000) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  applySearch(searchText: string) {
    if (searchText.length > 3 || searchText.length === 0) {
      this.searchText = searchText.trim();
      this.searchText = encodeURIComponent(this.searchText);
      this.offset = 0;
      this.primeTable.first = 0;
      this.fetchAndLoadData();
    }
  }
  getstatusReq(e, ind) {
    const payload = [];
    if (!this.isBinary) {
      payload.push({
        [this.statusReq[0].requestKey1]: this.dataSource[ind][this.statusReq[0].requestValue1],
        [this.statusReq[0].requestKey2]: e.checked,
      });
    } else {
      payload.push({
        [this.statusReq[0].requestKey1]: this.dataSource[ind][this.statusReq[0].requestValue1],
        [this.statusReq[0].requestKey2]: +e.checked,
      });
    }
    return payload;
  }

  getMessage(e) {
    if (e.checked) {
      return this.statusReq[0].messageChecked;
    } else {
      return this.statusReq[0].messageUnChecked;
    }
  }
  getMessageHead(e) {
    if (e.checked) {
      return this.statusReq[0].messageHeaderChecked;
    } else {
      return this.statusReq[0].messageHeaderUnChecked;
    }
  }

  handleChange(e, ind) {
    ind = ind - this.offset;
    let payload = [];
    payload = this.getstatusReq(e, ind);
    const message = this.getMessage(e);
    const messageHead = this.getMessageHead(e);

    this.confirmationService.confirm({
      message,
      header: messageHead,
      accept: () => {
        if (this.statusUrl) {
          this.gridService.changeUserStatus('change-status', this.statusUrl, payload).subscribe((result) => {
            this.refreshList();
          });
        }
        if (this.deleteUrl) {
          const sponsoredProductId = payload[0].sponsoredProductId;
          const url = `${this.deleteUrl}/${sponsoredProductId}`;
          this.gridService.deleteUserStatus('delete-sponsor-product', url).subscribe((result) => {
            this.refreshList();
          });
        }
      },
      reject: () => {
        this.refreshList();
      },
    });
  }

  getActionValue(key) {
    if (typeof key !== 'number') {
      return key === 'Active' ? 1 : false;
    }
    return key;
  }
  onProcessFileButtonClick(event, index) {
    if (this.statusReq && this.statusReq.length) {
      const status = this.statusReq[0].status;
      const url = `/api/v1/bulk-upload/${this.dataSource[index][this.statusReq[0].id]}/status/${status}`;
      this.gridService.processFile('process-file', url).subscribe((result) => {
        this.messageService.add({
          severity: 'success',
          key: 'tr',
          summary: 'Success',
          detail: result.message,
        });
        this.refreshList();
      });
    }
  }

  onEvaluateFileButtonClick(event, index) {
    if (this.statusReq && this.statusReq.length) {
      const status = this.statusReq[1].status;
      const url = `/api/v1/bulk-upload/${this.dataSource[index][this.statusReq[1].id]}/status/${status}`;
      this.gridService.processFile('process-file', url).subscribe((result) => {
        this.messageService.add({
          severity: 'success',
          key: 'tr',
          summary: 'Success',
          detail: result.message,
        });
        this.refreshList();
      });
    }
  }

  getDates(event, dateType) {
    if (!event.value) {
      return;
    }
    if (dateType === 'startDate') {
      const startTime = new Date(event.value);
      this.startDate = startTime;
    } else {
      const endTime = new Date(event.value);
      this.endDate = endTime;
    }
    this.refreshList();
  }
  onDateClear(dateType) {
    if (dateType === 'startDate') {
      this.startDate = null;
    } else {
      this.endDate = null;
    }
    this.refreshList();
  }
  setOrderOn(event, dateType) {
    if (!event.value && dateType !== 'clear') {
      return;
    }
    if (dateType === 'date') {
      const startTime = new Date(event.value);
      this.orderedFrom = startTime;

      const endTime = new Date(event.value);
      this.orderedTo = endTime;
      this.refreshList();
    } else if (dateType === 'clear') {
      this.orderedFrom = null;
      this.orderedTo = null;
      this.refreshList();
    }
  }
  sortByStatus(data) {
    this.sortDropDown = data.value;
    this.refreshList();
  }
  couponSwitches(e, ind, rowData) {
    this.couponSwitchForm.reset();
    this.currentIndex = ind;
    this.display = false;
    this.display = true;
    this.switchData = rowData;
    if (this.isPromo) {
      this.switchObj.promoCodeId = this.switchData.promoCodeId;
    } else {
      this.switchObj.comboOfferId = this.switchData.comboOfferId;
    }
  }
  onSelectStatus(event) {}
  changeStatus() {
    this.switchObj.inactiveReason = this.couponSwitchForm.value.inactiveReason;
    if (!this.couponSwitchForm.valid) {
      this.messageService.add({
        severity: 'error',
        key: 'tr',
        summary: 'Required',
        detail: this.commonService.getLabel('brandErrorDetail'),
      });
      return;
    }
    if (this.isPromo) {
      this.marketingService.updateCouponStatus(this.switchObj).subscribe((res: any) => {
        this.messageService.add({
          severity: 'success',
          key: 'tr',
          summary: 'Success',
          detail: this.commonService.getLabel('couponStatusUpdated'),
        });
        this.refreshList();
        this.couponSwitchForm.reset();
        this.display = false;
      });
    } else {
      this.marketingService.updateComboOfferStatus(this.switchObj).subscribe((res: any) => {
        this.messageService.add({
          severity: 'success',
          key: 'tr',
          summary: 'Success',
          detail: this.commonService.getLabel('comboStatusUpdated'),
        });
        this.refreshList();
        this.couponSwitchForm.reset();
        this.display = false;
      });
    }
  }
  cancelStatus(event) {
    this.dataSource[this.currentIndex].promoCodeEnabled = !this.switchData.promoCodeEnabled;
    this.refreshList();
    this.display = false;
  }

  routerLink(data: any) {
    return !this.edit.length ? this.route.snapshot.url : [this.edit[0], data[this.edit[1]]];
  }

  moreSelected(e) {
    this.selectedRow = e;
  }

  clickMore(e) {
    this.onMoreButtonClicked.emit({ button: e, payload: this.selectedRow });
  }
  getSwitchValue(event) {
    this.switchObj.published = event.value;
  }
  onClick(event) {
    this.onExportButtonClick.emit(event);
  }

  validateInputSwitch(actionSwitch, col) {
    return (
      actionSwitch.length &&
      ['Action', 'enable'].includes(col.field) &&
      !['promoCodeEnabled', 'comboOfferEnabled'].includes(actionSwitch[0]?.field)
    );
  }
  validateInputSwitch2(actionSwitch, col) {
    return actionSwitch.length && ['promoCodeEnabled', 'comboOfferEnabled'].includes(actionSwitch[0]?.field) && col.field === 'Action';
  }
  viewDetails() {
    this.onClickOfViewDetails.emit(this.miGridTitle);
  }
  setMonthDropDown() {
    this.monthList = utils.getDashboardDefaultValues().month.map((element, idx) => ({ name: element, value: idx + 1 }));
  }
  setCriteriaDropDown() {
    const criterias = utils.getDashboardDefaultValues().criteria;
    this.criteriaList = criterias.map((element, idx) => ({ name: element, value: idx + 1 }));
  }
  setCategoryDropDown() {
    const categories = ['All'];
    this.categoryList = categories.map((element, idx) => ({ name: element, value: idx + 1 }));
  }

  changeInDropdown(dropdownFlag, event) {
    this.filterDropdownChange.emit({ flag: dropdownFlag, value: event.value });
  }

  downloadFile(row) {
    const url = `/api/v1/bulk-upload/download-file/${row}`;
    this.inventoryService.downLoadSampleFile(url, 'Products.csv');
  }

  getTimezoneDate(date) {
    if (date !== 0) {
      return new Date(utils.getDateFromMoment(date));
    }
  }

  compareDateYear(date1, date2) {
    if (date1 !== 0 && date2 !== 0) {
      const esDate1 = new Date(utils.getDateFromMoment(date1));
      const esDate2 = new Date(utils.getDateFromMoment(date2));
      return esDate1.getFullYear() === esDate2.getFullYear();
    }
  }

  getJsonParseValue(e) {
    if (e) {
      return JSON.parse(e);
    }
  }

  getSearchedText() {
    this.searchTextByStore = '';
    this.searchTextByproduct = '';

    if (this.searchValueProduct) {
      this.searchTextByproduct = this.searchValueProduct.trim();
      this.searchTextByproduct = encodeURIComponent(this.searchTextByproduct);
    }
    this.offset = 0;
    this.primeTable.first = 0;
    this.fetchAndLoadData();
  }

  downloadExportReport() {
    const url = `/api/v1/bulk-upload/download-catalog/${this.exportFileType}/${utils.getCountryId()}/${utils.getLanguage()}`;
    this.inventoryService.downloadExportFile(url, 'Products.csv');
  }
}
