import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestApiService } from '../../services/rest-api.service';

@Injectable()
export class GridService {
  constructor(private restApiService: RestApiService) {}

  public loadGridData(url: string): Observable<{}> {
    return this.restApiService.get('mi-grid', url, 'page-center');
  }

  public changeUserStatus(d, url: string, data): Observable<any> {
    return this.restApiService.put(d, url, data, 'page-center');
  }

  public deleteUserStatus(d, url: string): Observable<any> {
    return this.restApiService.delete(d, url, 'page-center');
  }

  public changeStatus(d, url: string, data): Observable<any> {
    return this.restApiService.put(d, url, data, 'page-center');
  }

  processFile(d, url: string): Observable<any> {
    return this.restApiService.put(d, url, undefined, 'page-center');
  }

  getSkuGridData(d, url: string, data: any): Observable<any> {
    return this.restApiService.post(d, url, data, 'page-center');
  }
}
