import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/de';
import localeEnIN from '@angular/common/locales/en-IN';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessageService } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { PasswordModule } from 'primeng/password';
import { ToastModule } from 'primeng/toast';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpErrorHandler } from './modules/shared/services/http-error-handler.service';
import { SharedModule } from './modules/shared/shared.module';
import { PasswordManagementComponent } from './pages/password-management/password-management.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
registerLocaleData(localeFr);
registerLocaleData(localeEnIN, 'en-IN');

@NgModule({
  declarations: [AppComponent, SignUpComponent, SignInComponent, PasswordManagementComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    PanelModule,
    InputTextModule,
    EditorModule,
    DialogModule,
    MultiSelectModule,
    AppRoutingModule,
    FieldsetModule,
    OverlayPanelModule,
    CalendarModule,
    FileUploadModule,
    ConfirmDialogModule,
    CheckboxModule,
    ToastModule,
    PasswordModule,
    CardModule,
    SharedModule.forRoot(),
  ],
  providers: [HttpErrorHandler, MessageService],
  bootstrap: [AppComponent],
})
export class AppModule {}
