<section class="grid justify-content-end right-0">
  <div class="col-2 button-div">
    <button
      type="button"
      [icon]="isIcon ? 'pi pi-chevron-right' : 'none'"
      [label]="btnLabel | translate"
      pButton
      class="button"
      iconPos="right"
      (click)="onClick($event)"
      [disabled]="saveDisabled"
    ></button>
  </div>
</section>
