/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { environment } from '../../../environments/environment';

@Injectable()
export class AppSettings {
  // add À-Ÿ in regex pattern for spanish language accept chars.
  public static BASE_URL = environment.basePath;
  public static IS_IDLE_TIME = environment.idleTimeEnable;
  // public static IDLE_TIME = 60000;
  // public static IDLE_TIME_ON_CONFIRM_MSG = 60000;
  public static IDLE_TIME = 8;
  public static IDLE_TIME_ON_CONFIRM_MSG = 120000;
  public static NEW_USER_PASS = 'Dummy@12345';
  public static INTERVAL = 'interval';
  public static HEADER_CONTENT_TYPE = 'application/json';
  public static HEADER_AUTHORIZATION = 'Authorization';
  public static HEADER_TIMEZONE = 'Timezone';
  public static HEADER_TIMEZONE_VALUE = '';
  public static HEADER_ACCEPT_LANGUAGE = 'en-US';
  public static HEADER_AUTHORIZATION_VALUE = '';
  public static USER: any = null;
  public static TOKEN_KEY = 'Token';
  public static USER_DETAILS = 'userDetails';
  public static USER_ID = 'userId';
  public static ADMIN_ACCESS = '4';
  public static STORE_MANAGER_ACCESS = '6';
  public static FILE_UPLOAD_ID = '';
  public static FILE_UPLOAD_NAME = '';
  public static PROFILE_UPLOAD_ID = '';
  public static PROFILE_UPLOAD_NAME = '';
  public static FAQ: any = null;
  public static ACCESS_LIST = 'ACCESS_LIST';
  public static ACCESS_MENU = 'ACCESS_MENU';
  public static TIME_SETTINGS = 'TIME_SETTINGS';
  public static MIN_DISTANCE = 0.1;
  public static MAX_DISTANCE = 1;
  public static UPLOAD_FILE_URL = AppSettings.BASE_URL + '/api/v1/files/upload';
  public static GET_FILE_URL = AppSettings.BASE_URL + '/file';
  public static GET_FILE_THUMB_URL = AppSettings.BASE_URL + '/file/thumbnail_';
  public static EXPORT_URL = AppSettings.BASE_URL + '/';
  public static PRINT_URL = AppSettings.BASE_URL + '/';
  public static DEFAULT_PROFILE_IMAGE_URL = '/assets/images/default_profile.png';
  public static CLEAR_NOTIFICATION_INTERVAL: any;
  public static NOTIFICATION_INTERVAL: 600;
  public static IGNORE_LINK = [];
  public static SUPPORTED_COUNTRY: 'india';
  public static WEBSITE_PATTERN = new RegExp(
    ['^(http://www.|https://www.|http://|https://)?', '[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})', '?(/.*)?$'].join('')
  );
  public static NO_SPACE = new RegExp(['^\\S*$'].join(''));
  public static NO_SPACE_AT_START = new RegExp(['^(?!\\s).*$'].join(''));
  public static PHONE_NUMBER_PATTERN = new RegExp(['^[0-9]{10}$'].join(''));
  public static NAME_PATTERN = new RegExp(['^[A-Za-zÀ-Ÿ\\d-_\\s]*$'].join(''));
  public static ROLE_PATTERN = new RegExp(['^[A-Za-zÀ-Ÿ\\d-_\\s/\\\\]*$'].join(''));
  public static PHONE_PATTERN: any = '^[0-9][0-9]*$';
  public static NUMBER_PATTERN = /^\+?[0-9\-]+$/;
  public static AMOUNT_PATTERN = new RegExp(['^[0-9\\d.]*$'].join(''));
  public static PHONE_CODE = /([0-9]{4,})|[1-9]/;
  public static EMAIL_PATTERN = new RegExp(['[a-z|A-Z|0-9|À-Ÿ]+[@]+[a-z|A-Z|0-9|À-Ÿ]+[.]+[a-z|A-Z|0-9|À-Ÿ]+'].join(''));
  // public static EMAIL_PATTERN = new RegExp(['/^\S+@\S+\.\S+$/'].join(''));
  public static NEW_EMAIL_PATTERN = new RegExp(
    [
      '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@',
      '((\\[[\\d]{1,3}\\.[\\d]{1,3}\\.[\\d]{1,3}\\.[\\d]{1,3}])|(([a-zA-ZÀ-Ÿ\\-\\d]+\\.)+[a-zA-ZÀ-Ÿ]{2,15}))$',
    ].join('')
  );
  public static ZIPCODE_PATTERN = new RegExp(['^[0-9]'].join(''));
  public static DIGIT_PATTERN = new RegExp(['^[\\d]*$'].join(''));
  public static RADIUS_DIGIT_PATTERN = /^[0-9|.|0-9]+$/;
  public static NUMBER_NOT_ZERO = new RegExp(['^[1-9][0-9]*$'].join(''));
  public static FEE_PATTERN = new RegExp(['^\\d+(.\\d{1,2})?$'].join(''));
  public static ALPHA_NUMERIC = new RegExp(['^[A-Za-z0-9À-Ÿ]'].join(''));
  public static VALUE_ONE_TO_HUNDRED = new RegExp(['^[1-9][0-9]?$|^100$'].join(''));
  public static NON_ZERO_VALUES = new RegExp(['^[1-9][0-9]*$'].join(''));
  public static PASSWORD_PATTERN = new RegExp(['^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$'].join(''));
  // eslint-disable-next-line @typescript-eslint/quotes
  public static FIRST_NAME_PATTERN = new RegExp(["^(?![, '-.@#!$%&*?;])\\S[a-zA-Z0-9]*$"].join(''));
  public static HOTEL_PROFILE_UPLOAD_ID = '';
  public static HOTEL_PROFILE_UPLOAD_NAME = '';
  public static PERSON_NAME_PATTERN = '^[a-zA-ZÀ-Ÿ][a-zA-ZÀ-Ÿ\\s]+$';
  public static UNIT_OF_PRICE_PATTERN = '^[a-zA-Z0-9À-Ÿ\\s]+$';
  public static PRODUCT_NAME_PATTERN = '^[a-zA-ZÀ-Ÿ0-9&±.,-_\'"][a-zA-ZÀ-Ÿ0-9&±\\s.,-_\'"]+$';
  public static COMPANY_AND_PERSON_NAME_PATTERN = '^[a-zA-Z0-9À-Ÿ][a-zA-Z0-9À-Ÿ\\s-_]+$';
  public static FAX_PATTERN = /^\+?[0-9\-]+$/;
  public static TIME_ZONE_FIRST_STRING = /[(]/gi;
  public static TIME_ZONE_SECOND_STRING = /[)]/gi;
  public static DAYS_COLLECTION = [
    { label: 'Sun', value: '1' },
    { label: 'Mon', value: '2' },
    { label: 'Tue', value: '3' },
    { label: 'Wed', value: '4' },
    { label: 'Thu', value: '5' },
    { label: 'Fri', value: '6' },
    { label: 'Sat', value: '7' },
  ];

  public static COUNTRY_LIST = [
    { label: 'Australia', value: 'AU' },
    { label: 'Brazil', value: 'BR' },
    { label: 'China', value: 'CN' },
    { label: 'Egypt', value: 'EG' },
    { label: 'France', value: 'FR' },
    { label: 'Germany', value: 'DE' },
    { label: 'India', value: 'IN' },
    { label: 'Japan', value: 'JP' },
    { label: 'Spain', value: 'ES' },
    { label: 'United States', value: 'US' },
  ];

  public static LOGGED_IN_ROLE = '';
  public static roles = [
    {
      roleCode: 'System Admin',
      redirect: '/admin-dashboard',
    },
  ];

  public static DATE_FORMATTER = 'MMM DD, YYYY hh:mm A';

  public static COOKIE_EXPIRY = 15 * 24 * 60 * 60 * 1000;

  public static FILE_UPLOAD_MAX_SIZE_IN_BYTE = 10000000;

  public static SESSION_KEY_NAME = 'X-SESSION-KEY';

  public static COUNTRY_LIST_LANGUAGE = [
    { label: 'English', value: 'en' },
    { label: 'Portuguese', value: 'pt' },
    { label: 'Spanish', value: 'es' },
  ];
  public static GET_STATUS_DROPDOWNLIST = [
    { label: 'All', value: 'all' },
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' },
  ];

  public static GET_STATUS_NOTIFICATION_DROPDOWNLIST = [
    { label: 'All', value: '' },
    { label: 'Draft', value: 'DRAFT' },
    { label: 'Sent', value: 'SENT' },
  ];

  public static TEXT_COL_ARRAY = [
    'Action',
    'categoryImageUrl',
    'imageUrl',
    'brandImage',
    'createdAt',
    'processButtonLabel',
    'startDate',
    'endDate',
    'maxDiscount',
    'enable',
    'priceAndDate',
    'stock',
  ];

  public static greenArray = [
    'Available',
    'Active',
    'Online',
    'Sent',
    'Delivery completed',
    'Orden entregada',
    'Disponible',
    'En línea',
    'ORDERED',
    'Activo',
    'Enviada',
    'Completed',
    'Terminada',
  ];
  public static redArray = [
    'Not Available',
    'Inactive',
    'No disponible',
    'CANCELED',
    'Offline',
    'Desconectado',
    'Inactivo',
    'Failed',
    'Fallida',
    'Cancel Order',
    'Orden cancelada',
    'Canceled',
    'Cancelar',
  ];
  public static blackArray = ['Processing', 'Procesando'];
  public static orangeArray = ['Draft', 'READY_FOR_DELIVERY', 'Process', 'Proceso'];
  public static blueArray = [];
  public static yellowArray = [
    'Draft',
    'Borrador',
    'Order Created',
    'Orden creada',
    'Waiting for delivery partner allocation',
    'En espera de asignación de domiciliario',
    'Delivery Partner Assigned',
    'Domiciliario asignado',
    'On the way to the store',
    'De camino a la tienda',
    'At the store',
    'En la tienda',
    'Order Picked',
    'Orden recogida',
    'Task Waiting',
    'Tarea en espera',
    'On route to delivery address',
    'En camino a la dirección de entrega',
    'At delivery address',
    'En la dirección de entrega',
  ];
  public static brownArray = ['Publicada', 'Published'];

  public static ALL = 'All';
  public static bannerALL = 'ALL';
  public static BANNER_CATEGORY = 'CATEGORY';
  public static NONE = 'NONE';
  public static notificationAll = 'All';
  public static CATEGORY = 'category';
  public static CATEGORIES = 'categories';
  public static PRODUCTS = 'products';
  public static STORES = 'stores';
  public static BANNER_COMBO = 'combo_offer';
  public static USERS = 'users';
  public static CITIES = 'cities';
  public static PERCENTAGE = 'PERCENTAGE';
  public static POPUP = 'POPUP';
  public static BANNERHOME = 'BANNERHOME';
  public static HOMESCREEN_BANNER_2 = 'HOMESCREEN_BANNER_2';
  public static HOMESCREEN_BANNER_3 = 'HOMESCREEN_BANNER_3';
  public static HOMESCREEN_BANNER_3_RIGHT = 'HOMESCREEN_BANNER_3_RIGHT';
  public static HOMESCREEN_VIDEO = 'HOMESCREEN_VIDEO';
  public static HOMESCREEN_BANNER_4 = 'HOMESCREEN_BANNER_4';
  public static HOMESCREEN_BANNER_5 = 'HOMESCREEN_BANNER_5';
  public static HOMESCREEN_OCCASION = 'HOMESCREEN_OCCASION';

  public static CITY = 'City';
  public static STORE = 'Store';
  public static DEVICE_TYPE = 'DeviceType';
  public static SENT = 'SENT';

  public static replaceItem = 'replaceItem';
  public static updateQty = 'updateQty';
  public static cancelItem = 'cancelItem';

  public static PRODUCT = 'Product';
  public static Category = 'Category';
  public static FABRIC = 'fabric';
  public static OCCASION = 'occasion';
  public static STYLE = 'style';
  public static COLOR = 'color';
  public static DATE_ARRAY = ['createdAt', 'startDate', 'endDate', 'effectiveFrom', 'effectiveTo', 'datetime', 'orderPlacedOn'];
  public static TIME_COLLECTION = [
    { label: '12:00 AM', value: '0' },
    { label: '12:30 AM', value: '1800000' },
    { label: '01:00 AM', value: '3600000' },
    { label: '01:30 AM', value: '5400000' },
    { label: '02:00 AM', value: '7200000' },
    { label: '02:30 AM', value: '9000000' },
    { label: '03:00 AM', value: '10800000' },
    { label: '03:30 AM', value: '12600000' },
    { label: '04:00 AM', value: '14400000' },
    { label: '04:30 AM', value: '16200000' },
    { label: '05:00 AM', value: '18000000' },
    { label: '05:30 AM', value: '19800000' },
    { label: '06:00 AM', value: '21600000' },
    { label: '06:30 AM', value: '23400000' },
    { label: '07:00 AM', value: '25200000' },
    { label: '07:30 AM', value: '27000000' },
    { label: '08:00 AM', value: '28800000' },
    { label: '08:30 AM', value: '30600000' },
    { label: '09:00 AM', value: '32400000' },
    { label: '09:30 AM', value: '34200000' },
    { label: '10:00 AM', value: '36000000' },
    { label: '10:30 AM', value: '37800000' },
    { label: '11:00 AM', value: '39600000' },
    { label: '11:30 AM', value: '41400000' },
    { label: '12:00 PM', value: '43200000' },
    { label: '12:30 PM', value: '45000000' },
    { label: '01:00 PM', value: '46800000' },
    { label: '01:30 PM', value: '48600000' },
    { label: '02:00 PM', value: '50400000' },
    { label: '02:30 PM', value: '52200000' },
    { label: '03:00 PM', value: '54000000' },
    { label: '03:30 PM', value: '55800000' },
    { label: '04:00 PM', value: '57600000' },
    { label: '04:30 PM', value: '59400000' },
    { label: '05:00 PM', value: '61200000' },
    { label: '05:30 PM', value: '63000000' },
    { label: '06:00 PM', value: '64800000' },
    { label: '06:30 PM', value: '66600000' },
    { label: '07:00 PM', value: '68400000' },
    { label: '07:30 PM', value: '70200000' },
    { label: '08:00 PM', value: '72000000' },
    { label: '08:30 PM', value: '73800000' },
    { label: '09:00 PM', value: '75600000' },
    { label: '09:30 PM', value: '77400000' },
    { label: '10:00 PM', value: '79200000' },
    { label: '10:30 PM', value: '81000000' },
    { label: '11:00 PM', value: '82800000' },
    { label: '11:30 PM', value: '84600000' },
    { label: '11:59 PM', value: '86400000' },
  ];

  public static TIME_COLLECTION_VALUES = [
    { label: '12:00 AM', value: '00:00' },
    { label: '12:30 AM', value: '00:30' },
    { label: '01:00 AM', value: '01:00' },
    { label: '01:30 AM', value: '01:30' },
    { label: '02:00 AM', value: '02:00' },
    { label: '02:30 AM', value: '02:30' },
    { label: '03:00 AM', value: '03:00' },
    { label: '03:30 AM', value: '03:30' },
    { label: '04:00 AM', value: '04:00' },
    { label: '04:30 AM', value: '04:30' },
    { label: '05:00 AM', value: '05:00' },
    { label: '05:30 AM', value: '05:30' },
    { label: '06:00 AM', value: '06:00' },
    { label: '06:30 AM', value: '06:30' },
    { label: '07:00 AM', value: '07:00' },
    { label: '07:30 AM', value: '07:30' },
    { label: '08:00 AM', value: '08:00' },
    { label: '08:30 AM', value: '08:30' },
    { label: '09:00 AM', value: '09:00' },
    { label: '09:30 AM', value: '09:30' },
    { label: '10:00 AM', value: '10:00' },
    { label: '10:30 AM', value: '10:30' },
    { label: '11:00 AM', value: '11:00' },
    { label: '11:30 AM', value: '11:30' },

    { label: '12:00 PM', value: '12:00' },
    { label: '12:30 PM', value: '12:30' },
    { label: '01:00 PM', value: '13:00' },
    { label: '01:30 PM', value: '13:30' },
    { label: '02:00 PM', value: '14:00' },
    { label: '02:30 PM', value: '14:30' },
    { label: '03:00 PM', value: '15:00' },
    { label: '03:30 PM', value: '15:30' },
    { label: '04:00 PM', value: '16:00' },
    { label: '04:30 PM', value: '16:30' },
    { label: '05:00 PM', value: '17:00' },
    { label: '05:30 PM', value: '17:30' },
    { label: '06:00 PM', value: '18:00' },
    { label: '06:30 PM', value: '18:30' },
    { label: '07:00 PM', value: '19:00' },
    { label: '07:30 PM', value: '19:30' },
    { label: '08:00 PM', value: '20:00' },
    { label: '08:30 PM', value: '20:30' },
    { label: '09:00 PM', value: '21-00' },
    { label: '09:30 PM', value: '21-30' },
    { label: '10:00 PM', value: '22-00' },
    { label: '10:30 PM', value: '22-30' },
    { label: '11:00 PM', value: '23:00' },
    { label: '11:30 PM', value: '23:30' },
    { label: '11:59 PM', value: '23:59' },
  ];

  public static STATIC_COUNTRY_CODE = [
    {
      createdBy: '5',
      updatedBy: '5',
      createdAt: 1665060270978,
      updatedAt: 1665060270978,
      countryId: 'CO',
      fullNameLocale: 'Columbia',
      supportedCurrency: 'COP',
      localNames: '[{"language_code":"en","value":"Columbia},{"language_code":"es","value":"Columbia"}]',
      countryCode: '+57',
      flagImgFileId: 'co.svg',
      flagImgUrl: 'https://flagcdn.com/co.svg',
    },
    {
      createdBy: '2',
      updatedBy: '2',
      createdAt: 1574762838716,
      updatedAt: 1650344761787,
      countryId: 'IN',
      twoLetterAbbreviation: 'IN',
      fullNameLocale: 'India',
      supportedCurrency: null,
      localNames: '[{"language_code":"en","value":"India},{"language_code":"en","value":"India"}]',
      countryCode: '+91',
      flagImgFileId: 'in.svg',
      flagImgUrl: 'https://flagcdn.com/null',
    },
  ];
  public static COLUMBIA_CONTACT_NUMBER = '+57';
  public static INDIA_CONTACT_NUMBER = '+91';
  public static SPECIAL_CHAR_PATTERN = '[a-zA-Z0-9À-Ÿ]+';
  public static months = moment.months();
  public static NET_SALE = ['', '0'];
  static deliveryByOrderValue = [`Mu  : 0`, 'Zuale  : 0', 'Quick  : 0'];
  static pointOfOrderOrigin = [`App`, 'Marketplace', 'Call Center'];
  static DEFAULT_LANGUAGE = 'en';
  static DEFAULT_COUNTRY = 'CO';
  public static LIST_OF_FREQUENCY = ['Monthly', 'Weekly'];
  public static DATE_FORMAT = 'dd-mm-yy';
  // static DEFAULT_CATEGORY = utils.getLanguage() === 'en' ? 'All' : 'Todas';
  static DEFAULT_CATEGORY = 'All';
  static DEFAULT_FREQUENCY = 'monthly';
  static CURRENT_MONTH = new Date().getMonth() + 1;
  static DEFAULT_MONTH = 1;
  static FREQUENCY = 'frequency';
  static WEEKLY = 'Weekly';
  static FROM = 'From';
  static To = 'To';
  public static DATE_FORMAT_LONG = 'dd MMMM yyyy h:mm aa';
  public static DATE_FORMAT_TABLE = 'dd MMM yyyy';
  public static DATE_FORMAT_TABLE_SHORT = 'dd MMM yy';
  public static DATE_FORMAT_TABLE_NO_YEAR = 'dd MMM';
  public static DATE_FORMAT_TRACKING = 'dd MMM yy hh:mm aa';
  public static COOKIE_EMAIL_LABEL = 'email';
  public static COOKIE_PASS_LABEL = 'data';

  public static MILI_SECONDS = 1000;
  public static CHAR_CODE_31 = 31;
  public static CHAR_CODE_48 = 48;
  public static CHAR_CODE_57 = 57;
  static DEFAULT_CURRENCY = 'INR';
  public static COLUMBIA_ID = 'CO';
  public static INDIA_ID = 'IN';
  public static status_completed = 'completed';
  public static status_draft = 'draft';
  public static status_evaluated = 'evaluated';
  public static status_evaluate = 'evaluate';
  public static status_evaluating = 'evaluating';

  public static COLOMBIAN_LOCALE = 'de-DE';
  public static DEFAULT_LOCALE = 'en-IN';
  public static DEFAULT_CURRENCY_DISPLAY = 'symbol-narrow';
  public static DEFAULT_COMPONENT_ROUTE = 'dashboard';
  public static DEFAULT_TIMEZONE = 'America/Bogota';
  public static ACCEPT_TYPE = '.jpg, .png, .webp';

  static HISTORICAL = 'historical';
  static ACTUAL = 'actual';
  public static COUPON_FIRST_PURCHASE = 'firstPurchase';
  public static COUPON_LAST_PURCHASE = 'lastPurchase';
  public static COUPON_CART_AMOUNT = 'cartAmount';
  public static COMBO_NORMAL = 'NORMAL';
  public static COMBO_LIST = 'LIST';

  public static COMBO_IMG_RATIO_FROM = 0;
  public static COMBO_IMG_RATIO_TO = 20;
  public static BANNER_IMG_RATIO_FROM = 0;
  public static BANNER_IMG_RATIO_TO = 20;
  public static ONE_IS_TO_ONE_FROM = 0;
  public static ONE_IS_TO_ONE_TO = 20;
  public static COMPONENT_UPLOAD_PRODUCT_IMAGES = 'upload-product-images';
  public static CASH = 'CASH';
  public static PINPAID = 'PINPAID';
  public static POST = 'Post';
  public static PUT = 'Put';

  public static PRODUCT_SMALL = 'PRODUCT_SMALL';
  public static BRAND = 'BRAND';
  public static LIMIT = 10;
  public static BANNER_SMALL = 'BANNER_SMALL';
  public static BANNER = 'BANNER';
  public static POPUP_V = 'POPUP_V';
  public static POPUP_H = 'POPUP_H';
  public static PROMO = 'PROMO';
  public static COMBO_SMALL = 'COMBO_SMALL';
  public static COMBO = 'COMBO';
  public static COMBO_WIDE = 'COMBO_WIDE';
  public static LOGO = 'LOGO';
  public static PRODUCT_IMG = 'PRODUCT';
  public static CATEGORY_IMG = 'CATEGORY';

  public static HOME = 'home';
  public static WORK = 'work';
  public static OTHER = 'other';

  public static UPLOAD_PRODUCT_TYPE_AUTOCOMPLETE = 'AUTOCOMPLETE';
  public static UPLOAD_PRODUCT_TYPE_TEXTAREA = 'TEXTAREA';
  public static CHAR_CODE_32 = 32;
  public static week_Days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
}
